import React, { useState, useEffect } from 'react'
import logoReto2021 from '../images/torneo2021/2021_VikidzLogo.png'

const API_LEADERBOARD_URL =
  'https://api.vikidz.io/challenge/leaderboard'

function formatRankings(results) {
  const rankings = []
  results
    .filter(result => Number(result.total) > 0)
    .forEach(result => {
      const index = rankings.findIndex(
        ranking => ranking.grade === Number(result.school_year)
      )
      if (index === -1) {
        rankings.push({
          grade: Number(result.school_year),
          results: [result],
        })
      } else {
        rankings[index].results.push({ ...result })
      }
    })
  return rankings.sort((a, b) => a.grade - b.grade)
}

const Grades = ({ grades, activeGrade, onClick }) => (
  <ol className="mt-8 flex items-center justify-center flex-wrap">
    {grades.map(grade => (
      <li
        key={grade}
        onClick={() => onClick(grade)}
        className={`m-2 px-5 py-4 font-fredoka rounded-full cursor-pointer text-white ${
          activeGrade === grade ? 'bg-challenge-form' : 'bg-blue'
        } transition-all`}
      >
        {`${grade}º`}
      </li>
    ))}
  </ol>
)

const Leaderboard = () => {
  const [rankings, setRankings] = useState([])
  const [hasError, setHasError] = useState(false)
  const [activeGrade, setActiveGrade] = useState(null)

  useEffect(() => {
    fetchRankings()
  }, [])

  useEffect(() => {
    if (rankings.length > 0) {
      setActiveGrade(rankings[0].grade)
    }
  }, [rankings])

  async function fetchRankings() {
    try {
      const res = await fetch(API_LEADERBOARD_URL)
      const data = await res.json()
      setRankings(formatRankings(data.results))
    } catch (error) {
      setHasError(true)
    }
  }

  function renderRankings() {
    const index = rankings.findIndex(ranking => ranking.grade === activeGrade)
    if (hasError) {
      return (
        <p className="py-4 text-center">
          Hubo un error al cargar los resultados, intenta más tarde.
        </p>
      )
    }
    if (index === -1) {
      return <p className="py-4 text-center">Cargando...</p>
    }
    return (
      <div className="mt-8 font-fredoka text-sm md:text-base">
        <div className="custom-container custom-container--extralarge ">
          <div className="flex items-start">
            <div className="w-2/12 px-4 py-2">Puesto</div>
            <div className="w-4/12 px-4 py-2 text-left">Profesor</div>
            <div className="w-4/12 px-4 py-2 text-left">Escuela</div>
            <div className="w-2/12 px-4 py-2 ml-auto">Total de gemas</div>
          </div>
        </div>
        {rankings[index].results.map((result, index) => (
          <div
            className={`${
              index === 0
                ? 'bg-challenge-form'
                : index % 2 === 0
                ? 'bg-white'
                : 'bg-blue-lighter'
            }`}
          >
            <div className="custom-container custom-container--extralarge text-black">
              <div className="flex items-start">
                <div className="w-2/12 px-4 py-2">{index + 1}</div>
                <div className="w-4/12 px-4 py-2 text-left">
                  {result.full_name}
                </div>
                <div className="w-4/12 px-4 py-2 text-left">
                  {result.school}
                </div>
                <div className="w-2/12 px-4 py-2 ml-auto ">
                  {Number(result.total).toLocaleString('es-MX')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  function handleGradeClick(grade) {
    setActiveGrade(grade)
  }

  return (
    <section className="pt-40 bg-challenge-bottom text-white">
      <div className="">
          <a
              href="/torneo">
          <img src={logoReto2021} className="custom-container p-10"></img>
          </a>
        </div>
      <div className="custom-container custom-container--large">
        <h2 className="subheading">Tablas de líderes</h2>

        <Grades
          grades={rankings.map(ranking => ranking.grade)}
          activeGrade={activeGrade}
          onClick={handleGradeClick}
        />
      </div>

      {renderRankings()}
    </section>
  )
}

export default Leaderboard
