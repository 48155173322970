import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Leaderboard from "../components/leaderboard"


const LeaderPage2021 = () => (
    <Layout isRetoPage={false}>
      <SEO
        lang="es"
        title="Torneo de Mate Vikidz 2021"
        description="Torneo de Mate Vikidz 2021"
      />
      <Leaderboard></Leaderboard>
      </Layout>
)

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "challenge/logo.png"}) {
      childImageSharp { fluid(maxWidth: 500) { ...GatsbyImageSharpFluid } }
    }
  }
`

export default LeaderPage2021